<template>
  <!-- <Loader class="mt-6 flex justify-center" v-if="showLoaderComp" /> -->
  <div class="flex flex-col justify-center items-center">
    <div
      class="mt-6 w-full hidden md:block lg:hidden justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.svg"
        alt="perpetual-logo"
      />
    </div>
    <HeaderGlobal
      :titlemenu1="titlemenu1"
      :to1="'/petrecord'"
      titlemenu2="Health Log"
      :to2="'/healthlogoverview'"
      titlemenu3="Select Symptom"
    />

    <div class="w-full pl-0 pt-8 pb-8 text-left font-proximaMedium">
      <div class="relative font-proximaLight">
        <input
          class="w-full rounded-md py-4 px-6"
          type="text"
          placeholder="Search for symptom"
          v-model="keyword"
          @keyup.enter="searchSym"
        />
        <img
          class="absolute top-4 right-5"
          src="@/assets/images/search-icon.png"
          alt="search-icon"
        />
      </div>
      <div class="mt-2 ml-1 text-xs font-proximaLight text-subheaderText">
        We could not find anything,
        <!-- FIXME: don't work -->
        <span class="text-success cursor-pointer">add new keyword?</span>
      </div>
      <ul class="h-96 overflow-y-auto overflow-x-hidden mt-4 font-proximaLight">
        <li
          class="pb-4 mt-2 border-b border-primary flex justify-between items-center"
          v-for="(data, i) in symptoms"
          :key="i"
        >
          <div>
            <h5 class="mb-2 text-subheaderText">
              {{ data.name }}
            </h5>
            <span
              class="px-3 py-1 mx-1 bg-primary text-white text-sm rounded-md"
              v-for="(detail, i) in data.alternative_symptom_names"
              :key="i"
              >{{ detail }}</span
            >
          </div>
          <label class="checkbox-container">
            <input
              type="checkbox"
              :value="{ id: i + 1, name: data.name }"
              v-model="inputValueSymptom"
              @change="symptomCheckbox"
            />
            <span class="checkmark"></span>
          </label>
        </li>
      </ul>
      <submit-button
        background="bg-primary"
        title="Confirm Selection"
        width="w-10/12 md:w-8/12"
        class="m-auto"
        @click.native="submitConfirm"
      />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import AddIcon from "../../icons/AddIcon.vue";
import FormError from "../../warning/FormError.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import Loader from "../../loader/Loader.vue";
import { mapGetters, mapActions } from "vuex";
import { nameOfPet } from "../../../methods/petMethods";

export default {
  components: {
    AddIcon,
    FormError,
    HeaderGlobal,
    Loader,
    SubmitButton,
  },
  data() {
    return {
      titlemenu1: "",
      pet: {},
      showAddpets: false,
      errorMsg: [],
      newpetRoute: "",
      inputValueSymptom: [],
      symptomsVal: [],
      keyword: "",
    };
  },
  computed: {
    ...mapGetters({
      symptoms: "healthlog/getSymptoms",
    }),
  },
  created() {
    const petObject = localStorage.getItem("pet");
    const pet = JSON.parse(petObject);
    this.pet = pet;

    this.titlemenu1 = nameOfPet(pet.species) + " record: " + pet.callName;
    this.getSymptoms();
  },

  methods: {
    ...mapActions({
      getSymptoms: "healthlog/getSymptoms",
      searchSymptoms: "healthlog/searchSymptoms",
    }),

    searchSym(event) {
      //console.log(this.keyword);
      /* FIXME: don't work */
      this.searchSymptoms(this.keyword);
    },

    symptomCheckbox() {
      this.symptomsVal = this.inputValueSymptom;
      console.log(this.symptomsVal);
    },

    submitConfirm() {
      this.$router.push({
        name: "HealthLogOverview",
        params: { data: this.symptomsVal },
      });
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 15px;
  width: 15px;
  border-radius: 3px;
  border: 2px solid #0ac0cb;
  background-color: #eee;
}

.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkbox-container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
  top: -1px;
  left: 3px;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
</style>
